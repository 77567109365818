body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.header-main{
  background-color: rgb(255,69,68);
  height: 100px;
}
.header{
  text-align: center;
  padding:32px
}
.graph-display{
  display:flex;
  flex-direction: col;
  align-items: center;
  flex-wrap: wrap;
  height: 300px;
  row-gap: 80px;
  column-gap: 90px;
  margin:50px 10px 40px 0;
  padding:30px;
}

.graph-button{
  height:50px;
  margin-top:100px;
}

.footer{
  position:relative;
  height:100px;
  background-color: aliceblue;
}
